// Migrated
<template>
  <div
    id="home-trips"
    class="w-12/12 mb-6 md:mb-5 mt-6"
  >
    <ScreenWidthProvider v-slot="{ isNarrower, isWider }">
      <div
        v-if="isWider('md', 1)"
        class="hidden md:block"
      >
        <Swiper v-bind="sliderOptions">
          <Trip
            v-for="(trip, index) in tripsWithoutFirst"
            :key="`t-${index}`"
            :trip="trip"
          />
        </Swiper>
      </div>
      <div
        v-if="isNarrower('md', 1)"
        class="block md:hidden"
      >
        <Trip
          v-for="(trip, index) in mobileTripsOutSlide"
          :key="`t-${index}`"
          :trip="trip"
        />
        <Swiper v-bind="sliderOptions">
          <Trip
            v-for="(trip, index) in mobileTripsInSlide"
            :key="`t-${index}`"
            :trip="trip"
          />
        </Swiper>
      </div>
    </ScreenWidthProvider>
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  data () {
    return {
      mobileTripsOutsideSlideCount: 2,
      sliderOptions: {
        slidesPerView: 1,
        pagination: false,
        spaceBetween: 30,
        constantDisplayNavigation: true,
        navigation: true,
        arrowsType: 'circular',
        loadingSkeletonAnimation: 'skeleton-slides',
        loadingSkeletonAspect: false,
        loadingSkeletonHeight: '400px',
        loop: true,
        onlyRenderVisibleSlides: true,
        preventSwiperUpdate: true,
        autoplay: {
          delay: 5000,
        },

        breakpoints: {
          [getTailwindBreakpointsPx().md]: {
            slidesPerView: 3,
          },
        },
      },
    }
  },

  computed: {
    ...mapState(useTripStore, {
      trips: 'categoryStart',
    }),

    firstTrip () {
      return this.trips?.[0] || null
    },

    tripsWithoutFirst () {
      return (this.trips || []).filter(trip => trip.id !== this.firstTrip.id)
    },

    mobileTripsOutSlide () {
      return this.tripsWithoutFirst.slice(0, this.mobileTripsOutsideSlideCount)
    },

    mobileTripsInSlide () {
      return this.tripsWithoutFirst.slice(this.mobileTripsOutsideSlideCount)
    },
  },
})
</script>

<style lang="scss" scoped>
.trip-item {
  min-height: 400px !important;
}
</style>
